import WOW from 'wowjs'
import Swal from 'sweetalert2';
import AOS from 'aos';
$('form#news-form').submit(function(){
  $.post($(this).attr('action'), $(this).serialize())
    .done(function(res){
      Swal.fire({
        icon: 'success',
        title: 'Tebrikler!',
        text: res.message,
      })
    })
    .fail(function(xhr, status, error){
      var obj = JSON.parse(xhr.responseText);
      obj = obj.errors;
      var errors = '';
      $.each(obj, function(key,val){
        errors += ' - '+val+"<br>";
      });
      Swal.fire({
        icon: 'error',
        title: 'Hata!',
        html: errors,
      })
    });
  return false;
})

AOS.init();
